<template>
  <div>
    <form @submit.prevent="submit" key="company">
      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-10">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <div class="mb-5">
        <div class="font-medium text-xl">Basic Information</div>
        <!-- <div class="text-orange-600 text-xs">* BVN Optional</div> -->
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-10">
        <div class="col-span-1">
          <form-group
            class="mb-0"
            leftIcon="person-outline"
            name="first_name"
            :form="form"
            v-model="form.data.first_name.value"
          >
            First Name <span class="text-orange-600">*</span>
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            leftIcon="person-outline"
            name="last_name"
            :form="form"
            v-model="form.data.last_name.value"
          >
            Last Name <span class="text-orange-600">*</span>
          </form-group>
        </div>
        <div class="col-span-1 sm:col-span-2">
          <form-group
            class="mb-0"
            type="email"
            leftIcon="mail-outline"
            name="email"
            :form="form"
            v-model="form.data.email.value"
          >
            Email Address <span class="text-orange-600">*</span>
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="phone_no"
            leftIcon="call-outline"
            name="phone_no"
            :form="form"
            v-model="form.data.phone_no.value"
          >
            Phone Number <span class="text-orange-600">*</span>
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            leftIcon="lock-closed-outline"
            name="bvn"
            :form="form"
            v-model="form.data.bvn.value"
          >
            BVN
          </form-group>
        </div>
      </div>

      <!-- <div class="mb-5">
        <div class="font-medium text-xl">Profile Information</div>
        <div class="text-orange-600 text-xs">* All Optional</div>
      </div> -->

      <!-- <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-10">
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="date"
            leftIcon="calendar-outline"
            name="date_of_birth"
            :form="form"
            v-model="form.data.date_of_birth.value"
          >
            Date Of Birth
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="select"
            :options="genders"
            leftIcon="transgender-outline"
            name="gender"
            :form="form"
            v-model="form.data.gender.value"
          >
            Gender
          </form-group>
        </div>

        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="select"
            :options="maritalStatuses"
            leftIcon="body-outline"
            name="marital_status"
            :form="form"
            v-model="form.data.marital_status.value"
          >
            Marital Status
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="text"
            leftIcon="lcation-outline"
            name="address"
            :form="form"
            v-model="form.data.address.value"
          >
            Home Address
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="select"
            :options="employmentTypes"
            leftIcon="briefcase-outline"
            name="emp_type"
            :form="form"
            v-model="form.data.emp_type.value"
          >
            Employment Type
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            leftIcon="briefcase-outline"
            name="position"
            :form="form"
            v-model="form.data.position.value"
          >
            Job Title
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="money"
            leftIcon="wallet-outline"
            name="salary"
            :form="form"
            v-model="form.data.salary.value"
          >
            Salary
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="select"
            :options="daysOfTheMonth"
            select-display-type="grid"
            :select-grid-columns="4"
            leftIcon="calendar-outline"
            name="salary_day"
            v-model="form.data.salary_day.value"
            :form="form"
          >
            Salary Day
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            leftIcon="wallet-outline"
            name="account_no"
            :form="form"
            v-model="form.data.account_no.value"
          >
            Account Number
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="select"
            :options="bankOptions"
            leftIcon="business-outline"
            name="bank_name"
            :form="form"
            v-model="form.data.bank_name.value"
          >
            Bank Name
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="select"
            :options="idTypes"
            leftIcon="reader-outline"
            name="id_type"
            :form="form"
            v-model="form.data.id_type.value"
          >
            ID Type
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            leftIcon="document-text-outline"
            name="id_number"
            :form="form"
            v-model="form.data.id_number.value"
          >
            ID Number
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="select"
            :options="states"
            leftIcon="reader-outline"
            name="state"
            :form="form"
            v-model="form.data.state.value"
          >
            State
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="select"
            :options="lgas"
            leftIcon="reader-outline"
            name="lga"
            :form="form"
            :disabled="!form.data.state.value"
            v-model="form.data.lga.value"
          >
            LGA
          </form-group>
        </div>
        <div class="col-span-1">
          <form-group
            class="mb-0"
            type="text"
            leftIcon="location-outline"
            name="company_address"
            :form="form"
            v-model="form.data.company_address.value"
          >
            Company Address
          </form-group>
        </div>
      </div> -->
      <div class="text-right">
        <button type="submit" class="btn btn-blue" :disabled="form.loading">
          <sm-loader class="sm-loader-white" v-if="form.loading" />
          <span v-else>Add User</span>
        </button>
      </div>
    </form>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        User Created Successfully
      </div>

      <div>
        <router-link :to="{ name: 'users' }" class="btn btn-blue m-1">
          View all Users
        </router-link>
        <router-link
          :to="{ name: 'users-onboarding', query: { userId: createdUserId } }"
          class="btn btn-blue m-1"
        >
          Complete Onboarding
        </router-link>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      createdUser: null,
      employmentTypes: [
        'Full Time',
        'Part Time',
        'Contract',
      ],
      idTypes: [
        { title: 'National ID Card', value: 'national_id_card' },
        { title: 'Driver’s License', value: 'drivers_license' },
        { title: 'Permanent Voter’s Card', value: 'voters_card' },
        { title: 'Nigerian International Passport', value: 'international_passport' },
      ],
      genders: [
        { title: 'Male', value: 'male' },
        { title: 'Female', value: 'female' },
      ],
      maritalStatuses: [
        { title: 'Single', value: 'single' },
        { title: 'Engaged', value: 'engaged' },
        { title: 'Married', value: 'married' },
        { title: 'Divorced', value: 'divorced' },
        { title: 'Widowed', value: 'widowed' },
      ],
      form: this.$options.basicForm([
        { name: 'first_name', rules: 'required|alpha' },
        { name: 'last_name', rules: 'required|alpha' },
        { name: 'email', rules: 'required|email' },
        { name: 'phone_no', rules: 'required|phone' },
        { name: 'bvn', rules: 'nullable' },

        // { name: 'date_of_birth', rules: 'nullable' },
        // { name: 'gender', rules: 'nullable' },
        // { name: 'marital_status', rules: 'nullable' },
        // { name: 'emp_type', rules: 'nullable' },
        // { name: 'position', rules: 'nullable' },
        // { name: 'salary', rules: 'nullable' },
        // { name: 'salary_day', rules: 'nullable' },
        // { name: 'address', rules: 'nullable' },
        // { name: 'account_no', rules: 'nullable' },
        // { name: 'bank_name', rules: 'nullable' },
        // { name: 'id_type', rules: 'nullable' },
        // { name: 'id_number', rules: 'nullable' },
        // { name: 'state', rules: 'nullable' },
        // { name: 'lga', rules: 'nullable' },
        // { name: 'company_address', rules: 'nullable' },
      ]),
    };
  },
  computed: {
    ...mapState(['banks']),
    bankOptions() {
      return this.banks.length ? this.banks.map((bank) => ({
        title: bank.name,
        value: bank.code,
      })) : [];
    },
    createdUserId() {
      return this.createdUser?.id;
    },
    daysOfTheMonth() {
      const days = [];

      for (let i = 0; i < 31; i += 1) {
        const day = i + 1;
        const value = day;
        const title = day.toPosition();
        days.push({ title, value });
      }

      return days;
    },
    states() {
      return this.$store.state.states.map((state) => state.name);
    },
    lgas() {
      return this.$store.state.states
        .find((state) => state.name === this.form.data.state.value)
        ?.locals.map((lga) => lga.name) || [];
    },
  },
  // watch: {
  //   lgas() {
  //     this.form.data.lga.value = '';
  //   },
  // },
  methods: {
    async submit() {
      if (this.validateForm(this.form)) {
        this.form.error = false;
        this.form.loading = true;

        await this.sendRequest('admin.users.new', {
          data: {
            ...this.getFormData(this.form),
          },
          success: (response) => {
            const { user } = response.data;
            this.createdUser = user;

            this.$refs.successModal.open();
            this.form = this.resetForm(this.form);
          },
          error: (error) => {
            this.form.error = error;
            this.mapFormErrors(this.form, error?.response?.data?.errors);
          },
        });

        this.form.loading = false;
      }
    },
  },
};
</script>
