<template>
	<div class="flex mb-32">
		<div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-2/3 2xl:w-1/2 mx-auto">
			<div class="flex items-center mb-5">
				<h1 class="text-2xl font-bold mr-3">Create a new user.</h1>
				<router-link :to="{ name: 'users-onboarding' }" class="btn btn-blue ml-auto">
					<!-- <span>ion-</span> -->
					<span>Existing User</span>
				</router-link>
			</div>

			<div class="card border border-blue-200 pt-16 pb-10 px-6 md:px-16 -mx-6 sm:mx-0">
				<!-- <multiple /> -->
				<!-- <div class="my-16 border-t border-blue-200"></div> -->
				<single />
			</div>
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="helpModal" @close="closeModal(modalKey, $refs.helpModal)">
			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Register a new employee.
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">Here you can register a company employee giving them access to the platform.</p>
					<p class="mb-2">
						You can choose to 
						<a href="#!" class="text-black font-normal" @click.prevent="tab = 'add-employee'">register one at a time</a> 
						or 
						<a href="#!" class="text-black font-normal" @click.prevent="tab = 'add-multiple'">upload a bunch at once</a>.
					</p>
				</div>
			</div>

			<button
				type="button"
				class="btn btn-blue"
				@click.prevent="$refs.helpModal.close"
			>
				Got it.
			</button>
		</modal>
	</div>
</template>

<script>
	export default {
		components: {
			single: require('./Single').default,
			// multiple: require('./Multiple').default,
		},
		data() {
			return {
				modalKey: 'staff-new-help',
				tab: 'single',
				tabs: [
					{name: 'single', title: 'Add an Employee'},
					{name: 'multiple', title: 'Add Multiple Employees'},
				],
			}
		},
		mounted() {
			// this.openModal(this.modalKey, this.$refs.helpModal);
		}
	}
</script>